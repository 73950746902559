import React from 'react';
import {View, StyleSheet } from '@react-pdf/renderer';
import PackageHeader from './PackageHeader';
import PackageRow from './PackageRow';
import PackageBlankSpace from './PackageBlankSpace';
import PackageFooter from './PackageFooter';
import RemarkFooter from './RemarkFooter';

const tableRowsCount = 20;

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginLeft: 10,
        marginTop: 10,
        borderWidth: 1,
        borderColor: '#bff0fd',
    },
});

  const PackageTable = ({packg}) => (

    <View style={styles.tableContainer}>
        <PackageHeader />
        <PackageRow cases={packg} />
        <PackageBlankSpace
        rowsCount={ tableRowsCount - packg.length}
        />
        <PackageFooter packg={packg} />
        <RemarkFooter />
    </View>

  );

  export default PackageTable;
