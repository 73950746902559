import React from 'react';
import { Page, Document, StyleSheet } from '@react-pdf/renderer';
import PackageTitle from './PackageTitle';
import PackageNo from './PackageNo';
import PackageBillShip from './PackageBillShip';
import PackageTable from './PackageTable';
// Create styles
const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 10,
        paddingTop: 20,
        paddingLeft:10,
        paddingRight:40,
        lineHeight: 1.2,
        flexDirection: 'column',
    }
  });

// Create Document Component
const SlipPDF = ({packg, packgNo}) => {
  if(!packg){
    return(
      <div>Loading..</div>
    )
  };
  // if(!packg.Billing_Address){
  //   return(
  //     <div>Loading..</div>
  //   )
  // };
console.log(packg)

  const date = new Date();
  const [month, day, year] = [date.getMonth() + 1, date.getDate(), date.getFullYear()];
  const newDate = month + "-" + day + "-" + year

  return(
      <Document title={"PackagePDF"}>
          <Page size="A4" style={styles.page}>
              <PackageTitle title='Package Slip'/>
              <PackageNo packg={packg} date={newDate} packgNo={packgNo}/>
              <PackageBillShip packg={packg[0]}/>
              <PackageTable packg={packg} />
              {/*<SlipItemsTable invoice={invoice} />
              <SlipFooter /> */}
          </Page>
      </Document>
    )

}



export default SlipPDF;
