import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({

    titleContainer:{
        flexDirection: 'row',
        marginTop: 1,
        justifyContent: 'center'
    },
    reportTitle:{
        fontSize: 6,
        textAlign: 'center',
        textTransform: 'uppercase',
    }
  });


  const InvoiceThankYouMsg = () => (
    <View style={styles.titleContainer}>
        <Text style={[styles.reportTitle, { fontSize: 7, lineHeight: 1 }]}>Make all checks payable to
        Lento Medical Innovation, Inc.
       
        </Text>
    </View>
  );

  export default InvoiceThankYouMsg
