import React, { Fragment} from 'react';
import { Text, View,  StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    invoiceNoContainer: {
        flexDirection: 'row',
        marginTop: 42,
        justifyContent: 'flex-end'
    },
    invoiceDateContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    invoiceDate: {
            fontSize: 12,
            fontStyle: 'bold',
    },
    label: {
        width: 100,
    },
    leftContainer: {
      width: '60%',
      marginLeft: 10,

    },
    rightContainer: {
      width: '40%'
    },
    invoiceAddressContainer: {
      flexDirection: 'row',
      marginTop: 40,
      justifyContent: 'flex-start'
    }

  });


  const PackageNo = ({packg, date, packgNo}) =>
  (
    <Fragment>
      <View style={{flexDirection: 'row'}}>
        <View style={styles.leftContainer}>
          <View style={styles.invoiceAddressContainer}>
              <Text style={[styles.label, { fontSize: 5, lineHeight: 1 }]}>15110 Northwest FreeWay, Suite 150 {"\n"}
              Houston, TX 77040{"\n"}
              Ph:(510)413-3230{"\n"}
              Fax:(510)413-7020</Text>
          </View >
        </View>
        <View style={styles.rightContainer}>
          <View style={styles.invoiceNoContainer}>
              <Text style={styles.label}>Package No:</Text>
              <Text style={styles.invoiceDate}>{packgNo}</Text>
          </View >
          <View style={styles.invoiceDateContainer}>
            <Text style={[styles.label, {width: 68}]}>Date: </Text>
            <Text >{date}</Text>
          </View >
        </View>
      </View>
    </Fragment>
  );

  export default PackageNo;
