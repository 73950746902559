import React, { useState, useContext } from 'react';
import {
  Link,
  Redirect,
  useHistory
} from "react-router-dom";
import { api_url } from "./authorize";
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Input from '@material-ui/core/Input';

import jwt_decode from "jwt-decode";
import decode from 'jwt-decode';

// import bg from './img/background.jpg';  // 배경 이미지
// import md from './img/md.jpg';  // MD 이미지
// import ver from './img/Version01.jpg';  // 버전 이미지
// import udi from './img/udi.jpg';  // UDI 이미지
import balloons from './img/balloons.jpg';  
import lento_icon from './img/lento_icon.png';  

import { AuthorizeContext }  from './RootContext';

//console.log(balloons);

// 스타일 정의
const useStyles = makeStyles({
  root: {
    maxWidth: 400,
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",  // 카드의 중앙 정렬
    padding: '20px',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',  // 카드 배경에 약간의 투명도 추가
  },
  textField: {
    fontSize: 15,
    marginBottom: '.8em',
    margin: 'auto',
  },
  resize: {
    padding: '2em',
    fontSize: 20,
  },
  pos: {
    marginBottom: 12,
  },
  link: {
    display: 'block',
    marginTop: '1em',
    fontSize: '0.8em',
    textAlign: 'center',
  },
  version: {
    textAlign: 'center',
    fontSize: '0.9em',
    color: 'gray',
    marginTop: '1em',
  },
  logo: {
    textAlign: 'center',
    marginBottom: '1em',
  },
});

// const Login = ({ profile }) => {
//   const classes = useStyles();
//   const [authBody, setAuthBody] = useState({ username: '', password: '' });
//   const [authenticated, setAuthenticated] = useState(false);  // 인증 상태 관리
//   const [errorMessage, setErrorMessage] = useState('');  // 오류 메시지 관리
//   const [redirect, setRedirect] = useState(false)
//   const [cookies, setCookie] = useCookies(['name']);

const Login = ({ profile }) => {
  const classes = useStyles();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState('');  // 오류 메시지 관리
  let {setAuthBody, authBody, authenticated,
    setAuthenticated} = useContext(AuthorizeContext);
  // console.log(setAuthBody)
  const [redirect, setRedirect] = useState(false)
  const [cookies, setCookie] = useCookies(['name']);
  // 입력 값 변경 시 authBody 업데이트


  const onChange = (e) => {
    const { name, value } = e.target;
    setAuthBody({
      ...authBody,
      [name]: value,
    });
  };

  // 로그인 요청 처리 함수
  const handleSubmit = (e) => {
    e.preventDefault();
    // 백엔드 API로 로그인 요청
    axios({
      method: 'POST',
      url: `https://${api_url}/api/accounting_login?process_code=799`,  // 로그인 API 주소 (api_url을 사용)
      auth: authBody,  // 로그인 정보 (username, password) 전송
    })
      .then((res) => {
        if (res.data.token) {

          const token = res.data;
          console.log(res);

          // const decoded = jwt_decode(res.data.token, {header: true})
          let tt = String(res.data.token)
          if (tt.includes("b'")){
            tt =tt.replace("b'","");
            tt =tt.replace("'","");
          }
          sessionStorage.setItem('x-access-token', tt);  // 세션에 토큰 저장
          sessionStorage.setItem('role', token.role);  // 세션에 토큰 저장
          
          setAuthenticated(true);  // 인증 상태 업데이트
          console.log(res.data)     
          sessionStorage.setItem('authenticated', 'true');  // 세션에 인증 정보 저장
          window.location.reload();  // 페이지 새로고침
        } else if (res.data.message) {
          setErrorMessage(res.data.message);  // 오류 메시지 표시
        }
      })
      .catch((err) => {
        console.log(err);
        setErrorMessage('Login failed. Please try again.');  // 오류 메시지 표시
      });
  };

  const reloadPage = (e) => {
    e.preventDefault()
    if(authenticated == true){

      window.location.reload()
    }
  }

  // // 인증된 경우, 홈으로 리다이렉트
  // if (authenticated) {
  //   return <Redirect to="/accounthome" />;
  // }

   if(authenticated === true && sessionStorage.getItem('role') === 'accounting') {
    return <Redirect to="/accounthome" />
  }

  if(authenticated === true && sessionStorage.getItem('role') === 'manufacturer') {
    return <Redirect to="/shelfcheck" />
  }
 
  return (
    <div
      className="center"
      style={{
        // backgroundImage: `url(${balloons})`,
        // backgroundRepeat: 'no-repeat',
        // backgroundPosition: 'center',
        // backgroundSize: 'cover',
      }}
    >
    <img src={lento_icon}/>
      <Card className={classes.root} variant="outlined">
        <div className={classes.logo}>
          <h3 style={{ fontFamily: 'Roboto, sansSerif' }}>
            <strong>LENTO ACCOUNT/ PACKING SYSTEM</strong>
          </h3>
        </div>

        {errorMessage && <Typography color="error" style={{ textAlign: 'center' }}>{errorMessage}</Typography>}

        <form onSubmit={handleSubmit}>
          {/* 사용자명 입력 */}
          <Input
            className={classes.textField}
            placeholder="Enter Username"
            name="username"
            type="text"
            // value={authBody.username}
            onChange={onChange}
            fullWidth
            required
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
          />

          {/* 비밀번호 입력 */}
          <Input
            className={classes.textField}
            placeholder="Enter Password"
            name="password"
            type="password"
            // value={authBody.password}
            onChange={onChange}
            fullWidth
            required
            InputProps={{
              classes: {
                input: classes.resize,
              },
            }}
          />

          {/* 로그인 버튼 */}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            style={{ marginTop: '2em' }}
          >
            Log In
          </Button>
        </form>      
      </Card>
    </div>
  );
};

export default Login;