import React, { Fragment} from 'react';
import { Text, View,  StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    invoiceNoContainer: {
        flexDirection: 'row',
        marginTop: 36,
        justifyContent: 'flex-end'
    },
    invoiceDateContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end'
    },
    invoiceDate: {
            fontSize: 12,
            fontStyle: 'bold',
    },
    label: {
        width: 180
    },
    leftContainer: {
      width: '80%'
    },
    rightContainer: {
      width: '20%'
    },
    invoiceAddressContainer: {
      flexDirection: 'row',
      marginTop: 40,
      justifyContent: 'space-between',
      flexWrap: 'nowrap',
    }

  });


  const InvoiceNo = ({invoice}) => (
    <Fragment>
      <View style={{flexDirection: 'row'}}>
        <View style={styles.leftContainer}>
          <View style={styles.invoiceAddressContainer}>
              <Text style={[styles.label, { fontSize: 8, lineHeight: 1}]}>15110 Northwest FreeWay, Suite 150 {"\n"}
              Houston, TX 77040{"\n"}
              Ph:(510)413-3230  Fax:(510)413-7020
              </Text>
          </View >
        </View>
        <View style={styles.rightContainer}>
          <View style={styles.invoiceNoContainer}>
              <Text style={[styles.label, {width: 60}]}>Invoice No:</Text>
              <Text style={styles.invoiceDate}>{invoice[1]}</Text>
          </View >
          <View style={styles.invoiceDateContainer}>
            <Text style={[styles.label, {width: 40}]}>Date:</Text>
            <Text >{invoice[0]}</Text>
          </View >
        </View>
      </View>
    </Fragment>
  );

  export default InvoiceNo;
