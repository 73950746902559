import React, {Fragment} from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 18,
        fontStyle: 'bold',
    },
    description: {
        width: '30%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
        fontSize: 8,
    },
    qty: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'center',
        fontSize: 8,
    },
    caseid: {
      width: '25%',
      borderRightColor: borderColor,
      borderRightWidth: 1,
      textAlign: 'left',
      paddingLeft: 5,
      fontSize: 8,
    },

    unitPrice: {
        width: '17.5%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 4,
        fontSize: 8,
    },
    total: {
        width: '17.5%',
        textAlign: 'right',
        paddingRight: 4,
        fontSize: 8,
    },
  });


const InvoiceCaseRow = ({cases}) => {
  const numberWithCommas = (x) => {

      return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }

  const rows = cases.map( item => {
      let surg_info = item.Surgeon
      let patient_info = item.Patient

      return(
        <View style={styles.row} key={item.case_id}>
          <Text style={styles.caseid}>{item.case_id}</Text>
          <Text style={styles.description}>
          Knee Guide, Dr. {surg_info.First_Name} {surg_info.Last_Name}
          {/*&nbsp;,{'\n'}*/}
          {/*Patient {patient_info.First_Name} {patient_info.Last_Name}*/}
          </Text>
          <Text style={styles.qty}>{"1"}</Text>
          {/*<Text style={styles.qty}>{item.quantity}</Text>*/}
          <Text style={styles.unitPrice}> {item.Unit_Price}-{item.currency}
            {/*{numberWithCommas(Number.parseFloat(item.Unit_Price).toFixed(2))}*/}
          
          
          </Text>
          <Text style={styles.total}>
            {numberWithCommas(Number.parseFloat(item.Unit_Price).toFixed(2))}-{item.currency}
          </Text>
        </View>
      )

    }

  )


    return (
    <Fragment>
      {rows}
    </Fragment>
    )
};

export default InvoiceCaseRow;
