import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#90e5fc'
const styles = StyleSheet.create({
    // row: {
    //     flexDirection: 'row',
    //     borderBottomColor: '#bff0fd',
    //     borderBottomWidth: 1,
    //     alignItems: 'center',
    //     height: 24,
    //     fontSize: 12,
    //     fontStyle: 'bold',
    // },
    // description: {
    //     width: '85%',
    //     textAlign: 'right',
    //     borderRightColor: borderColor,
    //     borderRightWidth: 1,
    //     paddingRight: 8,
    // },
    // total: {
    //     width: '30%',
    //     textAlign: 'right',
    //     paddingRight: 8,
    // },
    column: {
        flex: 1, // 각 열이 동일한 공간을 차지하도록 설정
        justifyContent: 'center', // 세로 중앙 정렬
        alignItems: 'flex-end', // 가로 중앙 정렬
    },
    //   wireInfo: { 
    //     marginTop: 10, 
    //     fontSize: 10, 
    //     textAlign: 'left'
    // }
    row: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontSize: 12,
        fontStyle: 'bold',
    },
    description: {
        width: '65%', // 기존보다 줄여서 공간 확보
        textAlign: 'right',
        // borderRightColor: borderColor,
        // borderRightWidth: 1,
        // paddingRight: 8,
        marginTop: 3,
    },
    total: {
        width: '35%', 
        textAlign: 'right',
        paddingRight: 8,
    },
    footerContainer: {
        flexDirection: 'row', // 한 줄로 배치
        justifyContent: 'space-between', // 양쪽 정렬
        marginTop: 3,
    },
    wireInfo: { 
        width: '50%', // 왼쪽 공간 활용
        fontSize: 10, 
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingRight: 8,
        lineHeight: 1,

    },
    checkInfo: {
        width: '50%', // 오른쪽 공간 활용
        fontSize: 10,
        textAlign: 'right'
    },
    checkInfoContainer: {
        width: '50%', // 오른쪽 공간 활용
        flexDirection: 'column', // Tax와 Total을 세로 정렬
        alignItems: 'flex-end', // 오른쪽 정렬
    },
     checkInfoItem: {
        width: '100%',
        textAlign: 'right',
        paddingRight: 8,
        paddingTop: 2,
    },
    checkInfoDivider: {
        width: '100%',
        borderTopWidth: 1,
        borderTopColor: borderColor,
        marginTop: 3,
    },
     checkInfoRow: {
        flexDirection: 'row', // Tax와 값 나란히 배치
        alignItems: 'center', // 세로 중앙 정렬
        justifyContent: 'space-between',
        width: '100%',
        paddingRight: 8,
    },
    checkInfoDividerVertical: {
        width: 1, // 세로 선 두께
        height: 12, // Tax와 값 사이의 선 길이 조절
        backgroundColor: borderColor,
        marginHorizontal: 5, // 여백 추가
    },
  });


const InvoiceCaseFooter = ({invoice}) => {
    let inv_amt, inv_tax, inv_amt_tax;
    let ll = invoice.length
    let price = invoice[0].Unit_Price
    let Tax = invoice[0].Tax
    inv_amt = (ll * price) 
    inv_tax = inv_amt*(Tax/100)
    inv_amt_tax = inv_amt + inv_tax
    // balance_due = invoice.total_due
    // amt_paid = invoice.amt_paid
    const currency = invoice[0].currency
console.log(invoice)
    const numberWithCommas = (x) => {

        return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    }

    return(
        <View style={styles.footerContainer}>
            <View style={styles.wireInfo}>
                <Text>Wire Info:</Text>
                <Text>Bank of America, NA</Text>
                <Text>222 Broadway, New York, NY 10038</Text>
                <Text>Routing #: 026009593</Text>
                <Text>Account #: 81810476351</Text>
                <Text>SWIFT Code: BOFAUS3N</Text>
            </View> 
            <View style={styles.checkInfoContainer}>
                <View style={styles.checkInfoRow}>    
                    <Text style={styles.description}>Total:</Text>
                    <View style={styles.checkInfoDividerVertical} />
                    <Text style={styles.description}>{numberWithCommas(Number.parseFloat(inv_amt).toFixed(2))} {currency}
                    </Text>
                </View>
                <View style={styles.checkInfoDivider} />
                <View style={styles.checkInfoRow}>
                    <Text style={styles.description}>Tax:</Text>
                    <View style={styles.checkInfoDividerVertical} />
                    <Text style={styles.description}> {numberWithCommas(Number.parseFloat(inv_tax).toFixed(2))} {currency}
                    </Text>
                    
                </View>
                <View style={styles.checkInfoDivider} />
                <View style={styles.checkInfoRow}>    
                    <Text style={styles.description}>Balance_due:</Text>
                    <View style={styles.checkInfoDividerVertical} />
                    <Text style={styles.description}>{numberWithCommas(Number.parseFloat(inv_amt_tax).toFixed(2))} {currency}
                    </Text>
                </View>
            </View>             
        </View>


    )
};

  export default InvoiceCaseFooter
