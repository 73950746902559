import React, { createContext, useState, useEffect } from 'react';
import jwt_decode from "jwt-decode";
export const AuthorizeContext = createContext({});
//console.log(AuthorizeContext); 

export const AuthorizeProvider = (props) => {

  const [authenticated, setAuthenticated] = useState(() => JSON.parse(sessionStorage.getItem('authenticated')));
  const [authBody, setAuthBody] = useState(() => JSON.stringify(sessionStorage.getItem('x-access-token')));
 

  let token = authBody ? sessionStorage.getItem('x-access-token') : ""
  let user = token ? jwt_decode(token).user : ""
  let role = token ? jwt_decode(token).role : "visitor"

  // let decoded = ""


    useEffect(() => {
      const data = sessionStorage.getItem('x-access-token')
      const auth = sessionStorage.getItem('authenticated')
      if(data){
        const decoded = jwt_decode(authBody)
        setAuthBody( decoded || "visitor");
        //console.log(decoded) //role, user_id, fullname, exp
        //console.log(user) // none
        //console.log(role) //admin
        //console.log("Please Work", authBody) //token
      }
      if(auth){
        setAuthenticated(JSON.parse(auth))
        //console.log("Testing 2", authenticated) //True or False
      }
    }, []);


  const defaultContext = {
    authenticated,
    setAuthenticated,
    authBody,
    setAuthBody,
    token,
    user,
    role
  }
    return (<AuthorizeContext.Provider value={defaultContext}>{props.children}</AuthorizeContext.Provider>)

}
