import React, { Fragment} from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    headerContainer: {
        marginTop: 3,
        textAlign: 'right'

    },
    billTo: {
        marginTop: 1,
        paddingBottom: 1,
        fontFamily: 'Helvetica-Oblique'
    },
    leftContainer: {
      marginTop: 1,
      flex: 1,
      alignItems:'flex-start',
      marginRight: 75,
    },
    rightContainer: {
      marginTop: 0,
      flex: 1,
      width: '100%'
    },
    borderContainer: {
      paddingLeft: 10,
      paddingTop: 1,
      paddingBottom: 3, 
      width: 200,
      border: '1px solid black'
    },
    textStyle: {
      lineHeight: 0.5, // 줄 간격 줄이기
    },
  });


  const BillShipTo = ({invoice}) => (
    <Fragment>
    <View style={{ flexDirection: 'row'}}>
      <View style={styles.leftContainer}>
        <Text style={styles.billTo}>Bill To/Ship To:</Text>
        <View style={styles.borderContainer}>
          <Text style={styles.textStyle}>{invoice.Billing_Address.Office_Name}</Text>
          <Text style={styles.textStyle}>Atten.: A/P</Text>
          <Text style={styles.textStyle}>{invoice.Billing_Address.Street}</Text>
          <Text style={styles.textStyle}>
            {`${invoice.Billing_Address.City}, ${invoice.Billing_Address.Country} ${invoice.Billing_Address.Postal_Code}`}
          </Text>
        </View>
      </View>
      <View style={styles.rightContainer}>
        <Text style={styles.billTo}> Hospital Info:</Text>
        <View style={styles.borderContainer}>
          <Text style={styles.textStyle}>{invoice.Hospital.Office_Name}</Text>
          <Text style={styles.textStyle}>Atten.: A/P</Text>
          <Text style={styles.textStyle}>{invoice.Hospital.Street}</Text>
          <Text style={styles.textStyle}>{`${invoice.Hospital.City}, ${invoice.Hospital.Country}
          ${invoice.Hospital.Postal_Code}`}
          </Text>
        </View>
      </View>
    </View>
    </Fragment>

  );

  export default BillShipTo;
