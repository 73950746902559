import React, { useContext, useState } from 'react';
import { Router, Switch, NavLink, Redirect } from 'react-router-dom';
import { NavDropdown, MenuItem, NavItem, Modal, Button } from 'react-bootstrap';
import { AuthorizeContext } from "../RootContext";
import logo from '../img/lento_icon.png';
// import SurgeonConsoleTut from './tutVids/SurgeonConsoleTut';
// import HospitalConsoleTut from './tutVids/HospitalConsoleTut';
// import MRIConsoleTut from './tutVids/MRIConsoleTut';
// import DistributorConsoleTut from './tutVids/DistributorConsoleTut';
// import SurgicalProcedureTut from './tutVids/SurgicalProcedureTut';
// import './../dropdown.css';





const NavBar = (props) => {
  let { authenticated, setAuthenticated, authBody, setAuthBody} = useContext(AuthorizeContext);
  const [show, setShow] = useState(false)
  const [profile, setProfile] = useState(authBody || {role: "visitor"})
  //console.log(sessionStorage.getItem('packaging'))
  const hasRole = (profile, roles) =>{
    return roles.includes(profile.role)
  }

  const hasPermission = (profile) => {

    if(hasRole(profile, ['manufacturer'])){
      const permissions = profile.permissions
      console.log('yes true')

      return permissions.includes('Shipped')
      && sessionStorage.getItem('packaging')
    }
    //console.log("no")
    return false
  }

  const logout = () => {
    window.sessionStorage.clear()
    setAuthBody({role: "visitor"})
    setAuthenticated(false)
  }

  const internalLogout = () => {
    window.sessionStorage.clear()
    window.location.reload()
    setAuthenticated(false)
  }

  const handleClose = (video) => setShow(
    setShow(false)
  );
  const handleShow = (video) => {
    setShow(true)
  };


  return(
    <>
    <nav className="navbar navbar-expand-lg navbar-light bg-light mb-0" style={{zIndex: '200'}}>
      <a className="nav-link mb-0" href={hasPermission(profile) ?
        "/shippingCheck" : hasRole(profile, ['manufacturer']) ? "/finalQC" : "/"
      }><img src={logo} alt="Brand-logo" height="40"/> </a>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav">
          <li className="nav-item active">
            <NavLink to={hasPermission(profile) ? "/shippingCheck"
            : hasRole(profile, ['manufacturer']) ? "/finalQC"
            : "/"
            } className="nav-link">HOME<span className="sr-only"></span></NavLink>
          </li>
          <li className="nav-item active">
            {hasRole(profile, ['super_admin', 'write_admin', 'read_admin'])
              &&  <NavLink to="/manage" className="nav-link">MANAGE</NavLink>
            }
          </li>
          <li className="nav-item active">
            {hasRole(profile, ['super_admin', 'write_admin', 'read_admin'])
              &&  <NavLink to="/implant" className="nav-link">IMPLANT</NavLink>
            }
          </li>
          <li className="nav-item active">
            {hasRole(profile, ['super_admin', 'write_admin', 'read_admin'])
            &&  <NavLink to="/documents" className="nav-link">DOCUMENTS</NavLink>
            }
          </li>
          <li className="nav-item active">
            {hasRole(profile, ['super_admin', 'write_admin', 'read_admin'])
            &&  <NavLink to="/accounting" className="nav-link">ACCOUNTING</NavLink>
            }
          </li>
          <li className="nav-item active">
            {hasRole(profile, ['super_admin', 'write_admin', 'read_admin'])
            &&  <NavLink to="/research" className="nav-link">RESEARCH</NavLink>
            }
          </li>
          <li className="nav-item">
            <a className="nav-link" href={hasPermission(profile) ? "/packaging" : hasRole(profile,
              ['manufacturer']) ? "/warehouse" : "/"
            }
               onClick={hasRole(profile,['manufacturer']) ?
              internalLogout : logout
              }>
              LOGOUT
            </a>
          </li>
        </ul>
      </div>
      
    </nav>

    </>
  )
}

export default NavBar;
