import React, { memo, useContext,
  useState, useEffect, useRef
} from 'react';
import axios from 'axios';
import { PDFViewer, PDFDownloadLink, pdf } from '@react-pdf/renderer';

import styles from './../styles';
import {Button, Modal} from 'react-bootstrap';
import { Link, useParams, Redirect,useHistory } from "react-router-dom";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { AuthorizeContext } from "./../RootContext";
import { api_url } from "./../authorize";

import { makeStyles } from '@material-ui/core/styles';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckIcon from '@material-ui/icons/Check';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Input from './Input';
import SlipPDF from './packagePDF/slipPDF';
import InvoicePDF from '../invoice/InvoicePDF'// PDF


const useStyles = makeStyles(theme => ({
 root:{
   fontSize: "1.5em",

 },
 label:{
   fontWeight: 100,
   lineHeight: 1.2,
   letterSpacing: '0.00938em',
   fontSize: theme.typography.pxToRem(40)
 },
 sizeIcon: {
   width: 50,
   height: 50,
   color: "green"
 },
 rejectIcon: {
   width: 50,
   height: 50,
   color: "red"
 }
}));

var pdfData;

const ShippingCheck = memo((props) => {
 const { token, setAuthenticated, setAuthBody, authBody, authenticated } = useContext(AuthorizeContext);
 const classes= useStyles();
 const [data, setData] = useState([])
 const [id, setId] = useState(null)
 const [email, setEmail] = useState("")
 const [acctID, setacctID] = useState("")
 const [loading, setLoading] = useState(false)
 const [warning, setWarning] = useState(false)
 // const [packgNo, setPackgNo] = useState(null)
 const searchInput = useRef(null);
 let pdfData;


 // useEffect(() => {
 //   generatePdfDocument(data, packgNo, email)
 //   // console.log(data,"www--after useEffet")

 // }, [packgNo]);

const history = useHistory();
const goToShelf =()=>{
    history.push('/shelfcheck');
}
 function handleFocus(){
   searchInput.current.focus()
 }

 const handleKeyDown = (e) => {
   if (e.key === 'Enter') {
     const validCase = data.some(data => data.case_id === id)
     if(validCase == true){
       setData(prevState => (
         data.map((x, key) => (
           x.case_id === id ? {...x, checkIcon: true} : x
         ))
       ))
       e.target.value = ""
       setId("")
     }
     else{
       setWarning(true)
       e.target.value = ""
     }
   }
 }
 const validCase = (data) => {
   if(data.case_id === id){
     return true
   }
   else return false
 }
 const onChange = (e) => {
   setId(e.target.value)
 }
const handleClose = () => {
  setWarning(false)
  setId("")
}

const generatePdfDocument = async (data, packgNo, email) => {
   console.log(data,"www--after useEffet")
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        const blob_packing = await pdf((
            <SlipPDF
                packg={data}
                packgNo={packgNo}
            />
        )).toBlob();
        const blob_invoice = await pdf((
           <InvoicePDF
                invoice={data}
                inv_num={packgNo}
            />
        )).toBlob();
        const fileURL = URL.createObjectURL(blob_packing);
       
        pdfData = new FormData();
        pdfData.append('file_pack', blob_packing, `Packing_${packgNo}.pdf`)
        pdfData.append('file_inv', blob_invoice, `Invoice_${packgNo}.pdf`)
        console.log("packgNo:", packgNo)
        console.log("data:", data)
        console.log("pdfData:",pdfData.get("file_pack"))
        
        axios.post(`https://${api_url}/api/packing_invoice?package_no=${packgNo}&email=${email}`,
          pdfData,
          {headers: {
            'x-access-token' : token,
            'Content-Type': 'multipart/form-data'
          }}
        ).then(res =>{
          if(res.data.message){
             alert(res.data.message)
             window.location.reload()
           }
        else if(res.data.success)
        {
          alert(res.data.success)

          a.href = fileURL
          window.open(fileURL, '_blank');
          window.location.reload();
        };
        })
};

 const handleSearch = (acctID) => {
   setLoading(true)

   axios({
     method: 'get',
     url: `https://${api_url}/api/packing_invoice?acctid=${acctID}&lookup=Shipped`,
     headers: {
       'x-access-token' : token
     }
   }).then(res =>{
     setLoading(false)
     console.log("#1 API acctid resopne:", res.data);
     if(res.data.message){
       alert(res.data.message)
       window.location.reload()
     }
     else if(res.data.notLoggedIn){
       setAuthenticated(false)
       setAuthBody({role: 'packager'})
       alert("You have been logged out due to inactivity. Please log in again.")
     }
     else{
       handleFocus()
       setEmail(res.data[0].Hospital.Email_Address)
       console.log("resData:",res.data)
       setData(res.data.map(x =>
        (x.packaging_status === 1  ? {...x, checkIcon: true} : {...x, checkIcon: false})
      ))
     }
   })
 }
console.log("#2 include data:",data)


  const handleComplete = (e) => {
   setLoading(true)
   e.preventDefault()
   axios({
     method: 'put',
     url: `https://${api_url}/api/packing_invoice?email=${email}`,
     headers: {
       'x-access-token' : token
     }
   }).then(res =>{
     setLoading(false)
     console.log("API Response email:", res.data)
     if(res.data.message){
       alert(res.data.message)
       window.location.reload()
     }
     else if(res.data.notLoggedIn){
       setAuthenticated(false)
       setAuthBody({role: 'packager'})
       alert("You have been logged out due to inactivity. Please log in again.")
     }
     else{
       // setPackgNo(res.data)

      generatePdfDocument(data, res.data, email)
     }
   })
 }


 if (!authenticated){
   return(
     <Redirect to='/packing' />
   )
 }

 return(
   <>
   <div className="case_detail_layout">
    <div>
    <label style={{margin: '1em'}}>Acct ID: </label>
    <input className="barcode-label"
      name="acctID"
      value={acctID}
      onChange={(e) => setacctID(e.target.value)}
      onKeyDown={(e) => e.key === 'Enter' ? handleSearch(acctID) : null}
      autoFocus/>
      <Button className="btn btn-lg btn-warning"
        style={{margin:"0 25%"}}
        onClick={goToShelf}
        > Shelf Check
      </Button>
    <Input onKeyDown={handleKeyDown} onChange={onChange} id={id}
      email={email} ref={searchInput}
    />
    
    </div>
    <TableContainer>
      <Table sx={{ width: 80 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell
              style={{fontSize: 40}}
              align="center">Case ID</TableCell>
            <TableCell
              style={{fontSize: 40}}
            align="center">Scanned</TableCell>

          </TableRow>
        </TableHead>


        <TableBody>
          {
            data ?

            data.map((row, index) => (
              <TableRow
                key={row.case_id}
              >
                <TableCell align="center" style={{fontSize: 30}}>
                  {row.case_id}
                </TableCell>
                <TableCell align="center" >
                  <span>
                    {row.checkIcon ? <CheckIcon fontSize="large"
                    color="primary" style={{fontSize: 50}}

                    /> :
                    <CheckBoxOutlineBlankIcon fontSize="large" />}
                  </span>
                </TableCell>

              </TableRow>
            ))

            :

            "Scan Shipping Customer"
          }

        </TableBody>

      </Table>
      <Dialog
        open={warning}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`WARNING CASE: ${id}`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          This case is not within the list.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button className="btn btn-primary" onClick={handleClose} style={{fontSize: '2em'}}>
              <span className="glyphicon glyphicon-ok"/>
              &nbsp;OK
          </button>
        </DialogActions>
      </Dialog>

    </TableContainer>

    <div className="row">
      <div className="col text-center">
    {/*<Button disabled={data.length > 0} onClick={handleSearch}>Search</Button>*/}
        {/*<Button
          disabled={data.length === 0}
          className="btn btn-lg btn-warning"
          onClick={handleIncomplete}
          style={{margin: '2em', marginRight:'10em'}}
          >
        Pending
        </Button>*/}
        <Button
          disabled={data.length === 0 || data.filter(e => e.checkIcon === false).length > 0}
          className="btn btn-lg btn-success"
          onClick={handleComplete}>Complete
        </Button>
      </div>

    </div>
  </div>

  </>
 )

})

export default ShippingCheck;
