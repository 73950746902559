//import React, { Fragment} from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    headerContainer: {
        marginTop: 36,
        textAlign: 'right'

    },
    billTo: {
        marginTop: 5,
        paddingBottom: 10,
        fontFamily: 'Helvetica-Oblique'
    },
    leftContainer: {
      flex: 1,
      alignItems:'flex-start',
      marginLeft: 10,
      marginRight: 75,
    },
    rightContainer: {
      flex: 1,
      width: '100%',
    },
    borderContainer: {
      paddingLeft: 10,
      paddingTop: 3,
      width: 200,
      border: '1px solid black'
    }
  });

  const PackageBillShip = ({packg}) => {
    if(!packg){
      return(<div>Loading...</div>)
    }
console.log(packg,'wwwwwwwww')

    return(
      <View style={{ flexDirection: 'row'}}>
        <View style={styles.leftContainer}>
          <Text style={styles.billTo}>Bill To/Ship To:</Text>
          <View style={styles.borderContainer}>
            <Text>{packg.Billing_Address.Office_Name}</Text>
            <Text>Atten.: A/P</Text>
            <Text>{packg.Billing_Address.Street}</Text>
            <Text>{`${packg.Billing_Address.City}, ${packg.Billing_Address.Country}
            ${packg.Billing_Address.Postal_Code}`}
            </Text>
          </View>
        </View>
        <View style={styles.rightContainer}>
          <Text style={styles.billTo}>Hospital info:</Text>
          <View style={styles.borderContainer}>
            <Text>{packg.Hospital.Office_Name}</Text>
            <Text>{packg.Hospital.Street}</Text>
            <Text>{`${packg.Hospital.City}, ${packg.Hospital.Country}
            ${packg.Hospital.Postal_Code}`}
            </Text>
          </View>

        </View>
      </View>

    );
  }




  export default PackageBillShip;
